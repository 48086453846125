.price-tracker-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48rem;
}

.tracker-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  width: 37.8rem;
  gap: 1rem;
  padding: 0.75rem;
  background: #0f1629;
  border-radius: 12px;
  border: 1px solid #34383f;
}

.token-card {
  background: #030711;
  border-radius: 12px;
  padding: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #34383f;
}

.token-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.price-token-icon {
  width: 24px;
  height: 24px;
}

/* Specific size for HEX token icon */
img[src*="hex.png"].price-token-icon {
  width: 24px;
  height: 21px;
}

.token-symbol {
  color: white;
  font-weight: bold;
}

.token-price {
  font-size: 1.1rem;
  color: white;
  height: auto;
  width: auto;
}

.price-change {
  height: auto;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  width: 64px;
  margin-left: auto;
}

.price-change.positive {
  color: #4CAF50;
}

.price-change.negative {
  color: #ff4444;
}

.time-period-toggles {
  display: flex;
  gap: 0.27rem;
  margin-bottom: 1rem;
  justify-content: right;
  flex-wrap: wrap;
  padding: 4px;
  border-radius: 32px;
  border: 1px solid #1D283A;
  background: #030711;
  width: 37%;
  margin-left: auto;
  margin-right: auto;
}

.time-toggle {
  padding: 8px 14px;
  background: transparent;
  color: #F8FAFC;
  border-radius: 40px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.9rem;
  border: none;
}

.time-toggle:hover {
  color: #14B8A6;
}

.time-toggle.active {
  background: #0F1629;
  border: 1px solid #1D283A;
  color: #14B8A6;
}

@media screen and (max-width: 650px) {
  .price-tracker-container {
    height: 10rem;
    width: 23rem;
    align-items: center;
  }
  .tracker-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.25rem;
    width: 22.5rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
  }

  .time-period-toggles {
    width: 16rem;
    margin-left: auto;
    gap: 0.25rem;
    padding: 3px;
  }

  .token-card {
    padding: 0.45rem;
    width: 10rem;
    font-size: 0.9rem;
  }

  .token-price {
    font-size: 0.9rem;
    margin-left: auto;
  }

  .price-change {
    font-size: 0.75rem;
    margin-left: auto;
  }

  .time-toggle {
    padding: 6px 12px;
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 384px) {
  .time-toggle {
    padding: 6px 12px;
    font-size: 0.8rem;
  }
  .time-period-toggles {
    gap: 0.25rem;
    padding: 3px;
    width: 15.6rem;
    margin-left: auto;
  }

  .tracker-container {
    width: 21.5rem;
  }

  .token-card {
    width: 9.55rem;
    font-size: 0.9rem;
  }
  
}
